<template>
  <card-form>
    <div class="row mt-4">
      <f-input 
        name="lote" 
        v-model="model.lote" 
        label="Lote"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="dataFechamento" 
        :value="toStringDatetime(model.dataFechamento)" 
        label="Data de Fechamento"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="hospital" 
        v-model="model.hospital" 
        label="Hospital"
        :disabled="true"
        :cols="4" />
      <f-input 
        name="convenio" 
        v-model="model.convenio" 
        label="Convênio"
        :disabled="true"
        :cols="4" />
    </div>

    <div class="row">
      <f-input 
        name="status" 
        :value="translateStatusLote(model.status)" 
        label="Status"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="uploadData" 
        :value="toStringDatetime(model.uploadData)" 
        label="Data de Upload"
        :disabled="true"
        :cols="2" />
      <f-input
        name="auditadoPor" 
        :value="model.auditadoPor" 
        label="Auditado por"
        :disabled="true"
        :cols="3" />
      <f-input 
        name="dataUltimaAlteracao" 
        :value="toStringDatetime(model.dataUltimaAlteracao)" 
        label="Última Alteração"
        :disabled="true"
        :cols="2" />
    </div>

    <div class="row">
      <f-input 
        name="valorTotal" 
        :value="$options.filters.toCurrency(model.valorTotal)" 
        label="Valor"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="glosa" 
        :value="$options.filters.toCurrency(model.glosa)" 
        label="Total Glosa"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="valorFinal" 
        :value="$options.filters.toCurrency(model.valorFinal)" 
        label="Valor Final"
        :disabled="true"
        :cols="2" />

        <div class="col-md-6">
          <fieldset class="form-group mb-3 form-group-input">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" style="color: white;">.</legend>
            <div class="d-flex" style="gap: 10px;">
              <!-- TODO: authorize -->
              <b-button pill variant="success" @click="exportar">
                <i class="uil uil-file-download-alt"></i>
                Download XML
              </b-button>
              
              <!-- TODO: authorize -->
              <b-button pill variant="success" @click="openModalRepresentante()">
                <i class="uil uil-file-graph"></i>
                Relatório de auditoria
              </b-button>
              
              <div class="d-flex mt-2" style="gap: 10px;">
                <!-- TODO: authorize -->
                <b-form-checkbox
                  id="relatorio-auditoria-glosa"
                  v-model="optRelatorioAuditoria.glosa"
                >
                  Glosa
                </b-form-checkbox>

                <!-- TODO: authorize -->
                <b-form-checkbox
                  id="relatorio-auditoria-opme"
                  v-model="optRelatorioAuditoria.opme"
                >
                  OPME
                </b-form-checkbox>
              </div>
            </div>
          </fieldset>
        </div>
    </div>

    <h4>Guias</h4>

    <div class="row mb-2">
      <div class="col-sm-12 col-md-3">
        <label class="d-inline-flex align-items-center">
          Guia:
          <b-form-input
            v-model="filter.codigoGuia"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>

      <div class="col-sm-12 col-md-3">
        <label class="d-inline-flex align-items-center">
          Código Beneficiário:
          <b-form-input
            v-model="filter.codigoBeneficiario"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>

      <div class="col-sm-12 col-md-6">
        <label class="d-inline-flex align-items-center">
          <span class="d-block">Status:</span>
          <div v-for="status in statusList" :key="status.value" class="ms-2">
            <input
              type="radio"
              class="form-check-input me-1"
              :id="`status-${status.value}`"
              v-model="filter.status"
              :value="status.value"
            />
            <label class="form-check-label" :for="`status-${status.value}`">
              {{ status.label }}
            </label>
          </div>
        </label>
      </div>
    </div>

    <div class="table-responsive">
      <b-table 
        striped hover small 
        :items="guias2show"
        :fields="colunas"
        :tbody-tr-class="destacarLinha"
        :selectable="$hasPerm('lotesScLotesAuditarVarias') || $hasPerm('lotesScLotesReabrirVarias')"
        @row-selected="onRowSelected"
        :class="{
          // tentei controlar o selectable com botão e dataobj-react, mas não funcionou
          'c-pointer': $hasPerm('lotesScLotesAuditarVarias') || $hasPerm('lotesScLotesReabrirVarias'),
        }"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        
        <template #cell(valorTotal)="data">
          {{ data.item.valorTotal | toCurrency }}
        </template>
        <template #cell(glosa)="data">
          <span v-if="data.item.glosa > 0">
            {{ data.item.glosa | toCurrency }}
          </span>
        </template>
        <template #cell(valorFinal)="data">
          {{ data.item.valorFinal | toCurrency }}
        </template>
        <template #cell(status)="data">
          <div v-html="drawStatusAuditoria(data.item.status, data.item.statusComAlteracao)"></div>
        </template>

        <template #cell(action)="data">
          <b-button-group size="sm" class="w-100 ">
            <b-button v-if="data.item.ativo"
              class="flex-grow-0"
              variant="outline-primary"
              title="ver"
              v-authorize="'lotesScLotes'"
              @click="$router.push({
                name: 'faturamento.lotes.detalheguia.visualizar',
                params: {
                  arquivoId: model.id,
                  guiaIndex: data.item.index,
                  numeroLote: model.lote,
                },
              })"
            >
              <i class="uil uil-eye icon-size"></i>
            </b-button>

            <b-button v-if="data.item.ativo && editavel && data.item.status != 'A'"
              class="flex-grow-0"
              variant="outline-primary"
              title="Auditar"
              small
              v-authorize="'lotesScLotesAuditar'"
              @click="$router.push({
                name: 'faturamento.lotes.detalheguia.auditar',
                params: {
                  arquivoId: model.id,
                  guiaIndex: data.item.index,
                  numeroLote: model.lote,
                },
              })"
            >
              <i class="uil uil-pen icon-size"></i>
            </b-button>

            <b-button v-if="editavel && data.item.status != 'A' && data.item.ativo"
              class="flex-grow-0 ms-auto"
              variant="outline-primary"
              title="Remover guias"
              small
              v-authorize="'lotesScLotesRemoverGuia'"
              @click="handleRemoverGuia(data.item)"
            >
              <i class="fas fa-eraser icon-size"></i>
            </b-button>

            <b-button v-if="editavel && data.item.status != 'A' && !data.item.ativo"
              class="flex-grow-0 ms-auto"
              variant="outline-primary"
              title="Reativar guias"
              small
              v-authorize="'lotesScLotesReativarGuia'"
              @click="handleReativarGuia(data.item)"
            >
              <i class="fas fa-undo icon-size"></i>
            </b-button>
          </b-button-group>

        </template>

      </b-table>
      <p>
        <small v-if="!selecionando"
          class="text-muted"
          v-authorize="['lotesScLotesAuditarVarias', 'lotesScLotesReabrirVarias']"
        >
          * Clique na linha para selecionar várias guias
        </small>
        <b-button-group size="sm">
          <!--
          <b-button v-if="!selecionando"
            class="flex-grow-0 ms-auto"
            variant="outline-primary"
            title="Selecionar várias"
            small
            v-authorize="['lotesScLotesAuditarVarias', 'lotesScLotesReabrirVarias']"
            @click="selecionando = true"
          >
            Selecionar várias
          </b-button>
          -->

          <b-button v-if="selecionando"
            variant="outline-primary"
            title="Encerrar auditoria das selecionadas"
            small
            v-authorize="'lotesScLotesAuditarVarias'"
            @click="handleEncerrarAuditoriaSelecionadas"
          >
            <i class="uil uil-save"></i>
            Encerrar auditoria das selecionadas
          </b-button>

          <b-button v-if="selecionando"
            variant="outline-primary"
            title="Reabrir auditorias selecionadas"
            small
            v-authorize="'lotesScLotesReabrirVarias'"
            @click="handleReabrirAuditoriaSelecionadas"
          >
            <i class="uil uil-sync-exclamation"></i>
            Reabrir auditorias selecionadas
          </b-button>

        </b-button-group>

      </p>
    </div>

    <!-- o v-model não está funcionando direito, o código abaixo vai controlar qnd ele deve ser aberto -->
    <!-- nem no próprio vue-bootstrap está funcionando: https://bootstrap-vue.org/docs/components/modal#prevent-closing -->
    <b-modal
      ref="modal-representacao"
      title="Representação na assinatura digital"
      :hideHeaderClose="true"
      :centered="true"
      ok-only
      v-model="isModalRepresentacaoOpen"
    >
      <div class="filtro-inline">
        <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
        <b-form-group>
          <b-form-radio-group
            v-model="modalRepresentacao.usarAssinatura"
            :options="optionsRepresentacao"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group v-if="modalRepresentacao.usarAssinatura == 'S'">
          <b-form-checkbox v-model="modalRepresentacao.usarAssinaturaMedico">Usar assinatura do médico</b-form-checkbox> 
        </b-form-group>

        <f-selectsearch
          v-if="modalRepresentacao.usarAssinatura == 'S' && modalRepresentacao.usarAssinaturaMedico"
          label="Selecione o usuário:"
          name="modalUsuarioId"
          ref="modalUsuarioId"
          v-model="modalRepresentacao.representanteMedicoId"
          :cols="12"
          :searchFunction="pesquisarRepresentanteMedico"
          @select="addRepresentanteMedico"
          labelField="nome"
        />

        <b-form-group v-if="modalRepresentacao.usarAssinatura == 'S'">
          <b-form-checkbox v-model="modalRepresentacao.usarAssinaturaEnf">Usar assinatura do enfermeiro</b-form-checkbox> 
        </b-form-group>

        <f-selectsearch
          v-if="modalRepresentacao.usarAssinatura == 'S' && modalRepresentacao.usarAssinaturaEnf"
          label="Selecione o usuário:"
          name="modalUsuarioId"
          ref="modalUsuarioId"
          v-model="modalRepresentacao.representanteEnfId"
          :cols="12"
          :searchFunction="pesquisarRepresentanteEnf"
          @select="addRepresentanteEnf"
          labelField="nome"
        />

        <f-selectsearch
          label="Selecione guias específicas:"
          name="modalGuias"
          v-model="modalRepresentacao.guias"
          :cols="12"
          :multiple="true"
          :searchFunction="pesquisarRepresentanteGuia"
          :searchAllOptions="true"
          labelField="guia"
          valueField="guia"
          @select="addRepresentanteGuia"
        />
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="me-2"
            @click="() => { isModalRepresentacaoOpen = false; }"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="me-2"
            @click="utilizarRepresentante()"
          >
            Baixar
          </b-button>
        </div>
      </template>

    </b-modal>

    <template #footer>
      <button class="btn btn-info px-5" @click="voltar()" type="button">Voltar</button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import FSelectsearch from '@/components/Form/SelectSearch';
import appConfig from "@/app.config";
import { toStringDatetime } from '../../../utils/mixins.datetime'

import { translateStatusLote, drawStatusAuditoria, statusLoteRadios, statusRadio2Code } from '../../../utils/mixins.statuses'
import { trataErro } from "../../../utils/tratar.retornos";
import {
  getLoteDetalhe, exportarLote, downloadRelatorioAuditoriaFatura, getAllRepresentante,
  removerGuia, reativarGuia, encerrarAuditoriaGuiaVarias, reabrirAuditoriaGuiaVarias,
} from "../../../services/faturamento.service";
import { ATRIBUICAO } from "../../../services/atribuicao.service";
import { messageConfirm, messageConfirmInput, messageSuccess, messageError } from '../../../utils/messages';

export default {
  page: {
    title: "Lotes de faturamento",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CardForm,
    FSelectsearch
  },
  data() {
    return {
      userAttrId: null,
      exportarLote,
      toStringDatetime,
      translateStatusLote,
      drawStatusAuditoria,
      colunas: [
        { key: "selected", label: "" },
        { key: "codigoGuia", label: "Guia" },
        { key: "nomeBeneficiario", label: "Nome Beneficiário" },
        { key: "codigoBeneficiario", label: "Código Beneficiário" },
        { key: "valorTotal", label: "Valor total" },
        { key: "glosa", label: "Glosa" },
        { key: "valorFinal", label: "Valor Final" },
        { key: "itens", label: "Itens" },
        { key: "itensGlosa", label: "Itens Glosa" },
        { key: "status", label: "Status" },
        { key: "action", label: "Ações", thClass: 'text-center', tdClass: 'actions text-center text-nowrap' },
      ],
      model: {
        convenio: "",
        dataFechamento: "",
        guias: [ ],
        hospital: "",
        id: 0,
        lote: "",
        uploadData: "",
        uploadUsuario: "",
        tipoLote: "",
        originalFilename: "",
      },
      filter: {
        status: 'TODOS',
        codigoGuia: '',
        codigoBeneficiario: '',
      },
      statusList: [],
      editavel: false,
      optRelatorioAuditoria: {
        glosa: false,
        opme: false,
      },

      selecionando: false,
      linhasSelecionadas: [],

      isModalRepresentacaoOpen: false,
      optionsRepresentacao: [
        { text: 'Usar assinatura digital', value: 'S' },
        { text: 'Não usar assinatura digital', value: 'N' }
      ],
      modalRepresentacao: {
        usarAssinatura: 'S',

        usarAssinaturaMedico: false,
        usarAssinaturaEnf: false,

        representanteMedicoId: null,
        representanteEnfId: null,

        guias: [],
      },
    };
  },
  computed: {
    guias2show() {
      let guias = this.model.guias;
      
      if (!this.$hasPerm('lotesScLotesRemoverGuia') && !this.$hasPerm('lotesScLotesReativarGuia')) {
        guias = guias.filter(m => m.ativo);
      }

      let status = statusRadio2Code(this.filter.status);
      if (status) {
        guias = guias.filter(m => m.status == status);
      }

      let codigoGuia = this.filter.codigoGuia.toLowerCase();
      if (codigoGuia) {
        guias = guias.filter(m => m.codigoGuia.toLowerCase().indexOf(codigoGuia) !== -1);
      }

      let codigoBeneficiario = this.filter.codigoBeneficiario.toLowerCase();
      if (codigoBeneficiario) {
        guias = guias.filter(m => m.codigoBeneficiario.toLowerCase().indexOf(codigoBeneficiario) !== -1);
      }

      return guias;
    },

    guiasDataset() {
      return this.model.guias
        .filter(m => m.ativo)
        .map(m => { return { guia: m.codigoGuia } })
        .sort((a, b) => Number(a.guia) - Number(b.guia))
    },
  },
  mounted() {
    this.editavel = this.$route.meta.editavel;

    var user = JSON.parse(localStorage.getItem("user"));
    if (user) this.userAttrId = user.atribuicaoId;

    this.statusList = statusLoteRadios(true);

    this.atualizarLista();
  },
  methods: {
    atualizarLista() {
      getLoteDetalhe(this.$route.params.id).then(({ data }) => {
        this.model = data;
      });
    },
    openModalRepresentante() {
      this.resetModalRepresentacaoDataReact();
      this.isModalRepresentacaoOpen = true
    },
    resetModalRepresentacaoDataReact() {
      this.modalRepresentacao.usarAssinatura = 'S';
      this.modalRepresentacao.usarAssinaturaMedico = false;
      this.modalRepresentacao.usarAssinaturaEnf = false;
      this.modalRepresentacao.representanteMedicoId = null;
      this.modalRepresentacao.representanteEnfId = null;
      this.modalRepresentacao.guias = [];
    },

    pesquisarRepresentanteMedico(query) {
      return getAllRepresentante({
        filtro: query,
        atribuicaoIds: [ ATRIBUICAO.MEDICO ],
        ativo: true
      })
    },
    pesquisarRepresentanteEnf(query) {
      return getAllRepresentante({
        filtro: query,
        atribuicaoIds: [ ATRIBUICAO.ENFERMEIRO ],
        ativo: true
      })
    },
    addRepresentanteMedico(representante) {
      if (representante) {
        this.modalRepresentacao.representanteMedicoId = representante.id;
      } else {
        this.modalRepresentacao.representanteMedicoId = null;
      }
    },
    addRepresentanteEnf(representante) {
      if (representante) {
        this.modalRepresentacao.representanteEnfId = representante.id;
      } else {
        this.modalRepresentacao.representanteEnfId = null;
      }
    },
    pesquisarRepresentanteGuia(query) {
      return new Promise((resolve) => {
        resolve({
          data: this.guiasDataset.filter(m => m.guia.indexOf(query) !== -1)
        });
      });
    },
    addRepresentanteGuia(guias) {
      this.modalRepresentacao.guias = guias.map(m => m.guia);
    },
    utilizarRepresentante() {
      let executarDownload = false;
      if (this.modalRepresentacao.usarAssinatura == 'S') {
        executarDownload = true;
        if (!this.modalRepresentacao.usarAssinaturaMedico && !this.modalRepresentacao.usarAssinaturaEnf) {
          messageError(this, 'Selecione o médico e/ou o enfermeiro para a assinatura digital')
          executarDownload = false;
        } 
        if (this.modalRepresentacao.usarAssinaturaMedico && !this.modalRepresentacao.representanteMedicoId) {
          messageError(this, 'Selecione o usuário médico para a assinatura digital')
          executarDownload = false;
        } 
        if (this.modalRepresentacao.usarAssinaturaEnf && !this.modalRepresentacao.representanteEnfId) {
          messageError(this, 'Selecione o usuário enfermeiro para a assinatura digital')
          executarDownload = false;
        }
      } else {
        executarDownload = true;
      }

      if (executarDownload) {
        this.isModalRepresentacaoOpen = false;
        //download
        this.downloadRelatorioAuditoria()
      }
    },

    exportar() {
      exportarLote(this.$route.params.id, this.model.originalFilename)
        .catch((err) => {
          trataErro(err, this)
        })
    },
    downloadRelatorioAuditoria() {
      downloadRelatorioAuditoriaFatura(
        this.$route.params.id,
        this.modalRepresentacao.usarAssinatura == 'S',
        this.modalRepresentacao.representanteEnfId,
        this.modalRepresentacao.representanteMedicoId,
        this.optRelatorioAuditoria.glosa,
        this.optRelatorioAuditoria.opme,
        this.modalRepresentacao.guias,
        "relatorio-auditoria-fatura.pdf"
      )
        .catch((err) => {
          trataErro(err, this)
        })
    },
    voltar() {
      if (this.$route.params.backTo) {
        this.$router.push({path: this.$route.params.backTo})
      } else {
        this.$router.push({name: 'faturamento.lotes'})
      }
    },
    handleRemoverGuia(guia) {
      messageConfirmInput(
        this, '', `Deseja remover a guia "${guia.codigoGuia}"?`,
        'Qual o motivo da remoção?', 'text', (value) => {
          return new Promise((resolve) => {
            if (typeof value != 'undefined' && value != null && value != '') {
              resolve();
            } else {
              resolve("Necessário informar o motivo da remoção");
            }
          });
        }
      )
        .then((result) => {
          if (result.isConfirmed) {
            removerGuia(
              this.model.id,
              guia.index,
              result.value
            ).then(() => {
              messageSuccess(this, `Guia "${guia.codigoGuia}" removida`)
              this.atualizarLista();
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
    handleReativarGuia(guia) {
      messageConfirm(this, `Deseja reativar a guia "${guia.codigoGuia}"?`)
        .then((result) => {
          if (result.isConfirmed) {
            reativarGuia(
              this.model.id,
              guia.index
            ).then(() => {
              messageSuccess(this, `Guia "${guia.codigoGuia}" reativada`)
              this.atualizarLista();
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
    destacarLinha(item) {
      if (!item) return;
      return item.ativo ? '' : 'text-decoration-line-through guia-removida';
    },
    onRowSelected(rows) {
      if (rows.length == 0) {
        this.selecionando = false;
      } else {
        this.selecionando = true;
      }
      this.linhasSelecionadas = rows;
    },
    handleReabrirAuditoriaSelecionadas() {
      messageConfirm(this, `Deseja reabrir auditoria das guias selecionadas?`)
        .then((result) => {
          if (result.isConfirmed) {
            reabrirAuditoriaGuiaVarias(
              this.model.id,
              this.linhasSelecionadas.map(m => m.index)
            ).then(() => {
              messageSuccess(this, `Guias reativadas`)
              this.atualizarLista();
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
    handleEncerrarAuditoriaSelecionadas() {
      messageConfirm(this,
        "Ao encerrar a auditoria a guia irá para o status Auditado. Esta ação só é reversível por administradores",
        "Deseja encerrar a auditoria?"
      )
        .then((result) => {
          if (result.isConfirmed) {
            encerrarAuditoriaGuiaVarias(
              this.model.id,
              this.linhasSelecionadas.map(m => m.index)
            ).then(() => {
              messageSuccess(this, `Auditoria das guias encerradas`)
              this.atualizarLista();
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
  }
};
</script>

<style scoped>
:deep(.guia-removida > *:not(.actions)) {
  opacity: .25;
}
</style>