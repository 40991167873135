var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.voltar()
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-input", {
            attrs: { name: "lote", label: "Lote", disabled: true, cols: 2 },
            model: {
              value: _vm.model.lote,
              callback: function($$v) {
                _vm.$set(_vm.model, "lote", $$v)
              },
              expression: "model.lote"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "dataFechamento",
              value: _vm.toStringDatetime(_vm.model.dataFechamento),
              label: "Data de Fechamento",
              disabled: true,
              cols: 2
            }
          }),
          _c("f-input", {
            attrs: {
              name: "hospital",
              label: "Hospital",
              disabled: true,
              cols: 4
            },
            model: {
              value: _vm.model.hospital,
              callback: function($$v) {
                _vm.$set(_vm.model, "hospital", $$v)
              },
              expression: "model.hospital"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "convenio",
              label: "Convênio",
              disabled: true,
              cols: 4
            },
            model: {
              value: _vm.model.convenio,
              callback: function($$v) {
                _vm.$set(_vm.model, "convenio", $$v)
              },
              expression: "model.convenio"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("f-input", {
            attrs: {
              name: "status",
              value: _vm.translateStatusLote(_vm.model.status),
              label: "Status",
              disabled: true,
              cols: 2
            }
          }),
          _c("f-input", {
            attrs: {
              name: "uploadData",
              value: _vm.toStringDatetime(_vm.model.uploadData),
              label: "Data de Upload",
              disabled: true,
              cols: 2
            }
          }),
          _c("f-input", {
            attrs: {
              name: "auditadoPor",
              value: _vm.model.auditadoPor,
              label: "Auditado por",
              disabled: true,
              cols: 3
            }
          }),
          _c("f-input", {
            attrs: {
              name: "dataUltimaAlteracao",
              value: _vm.toStringDatetime(_vm.model.dataUltimaAlteracao),
              label: "Última Alteração",
              disabled: true,
              cols: 2
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("f-input", {
            attrs: {
              name: "valorTotal",
              value: _vm.$options.filters.toCurrency(_vm.model.valorTotal),
              label: "Valor",
              disabled: true,
              cols: 2
            }
          }),
          _c("f-input", {
            attrs: {
              name: "glosa",
              value: _vm.$options.filters.toCurrency(_vm.model.glosa),
              label: "Total Glosa",
              disabled: true,
              cols: 2
            }
          }),
          _c("f-input", {
            attrs: {
              name: "valorFinal",
              value: _vm.$options.filters.toCurrency(_vm.model.valorFinal),
              label: "Valor Final",
              disabled: true,
              cols: 2
            }
          }),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "fieldset",
              { staticClass: "form-group mb-3 form-group-input" },
              [
                _c(
                  "legend",
                  {
                    staticClass: "bv-no-focus-ring col-form-label pt-0",
                    staticStyle: { color: "white" },
                    attrs: { tabindex: "-1" }
                  },
                  [_vm._v(".")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex", staticStyle: { gap: "10px" } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { pill: "", variant: "success" },
                        on: { click: _vm.exportar }
                      },
                      [
                        _c("i", { staticClass: "uil uil-file-download-alt" }),
                        _vm._v(" Download XML ")
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { pill: "", variant: "success" },
                        on: {
                          click: function($event) {
                            return _vm.openModalRepresentante()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "uil uil-file-graph" }),
                        _vm._v(" Relatório de auditoria ")
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex mt-2",
                        staticStyle: { gap: "10px" }
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "relatorio-auditoria-glosa" },
                            model: {
                              value: _vm.optRelatorioAuditoria.glosa,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.optRelatorioAuditoria,
                                  "glosa",
                                  $$v
                                )
                              },
                              expression: "optRelatorioAuditoria.glosa"
                            }
                          },
                          [_vm._v(" Glosa ")]
                        ),
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "relatorio-auditoria-opme" },
                            model: {
                              value: _vm.optRelatorioAuditoria.opme,
                              callback: function($$v) {
                                _vm.$set(_vm.optRelatorioAuditoria, "opme", $$v)
                              },
                              expression: "optRelatorioAuditoria.opme"
                            }
                          },
                          [_vm._v(" OPME ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Guias")]),
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-sm-12 col-md-3" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _vm._v(" Guia: "),
              _c("b-form-input", {
                staticClass: "ml-2 form-control form-control-sm ms-2 ",
                model: {
                  value: _vm.filter.codigoGuia,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "codigoGuia", $$v)
                  },
                  expression: "filter.codigoGuia"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-3" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _vm._v(" Código Beneficiário: "),
              _c("b-form-input", {
                staticClass: "ml-2 form-control form-control-sm ms-2 ",
                model: {
                  value: _vm.filter.codigoBeneficiario,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "codigoBeneficiario", $$v)
                  },
                  expression: "filter.codigoBeneficiario"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("span", { staticClass: "d-block" }, [_vm._v("Status:")]),
              _vm._l(_vm.statusList, function(status) {
                return _c("div", { key: status.value, staticClass: "ms-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.status,
                        expression: "filter.status"
                      }
                    ],
                    staticClass: "form-check-input me-1",
                    attrs: { type: "radio", id: "status-" + status.value },
                    domProps: {
                      value: status.value,
                      checked: _vm._q(_vm.filter.status, status.value)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filter, "status", status.value)
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "status-" + status.value }
                    },
                    [_vm._v(" " + _vm._s(status.label) + " ")]
                  )
                ])
              })
            ],
            2
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("b-table", {
            class: {
              // tentei controlar o selectable com botão e dataobj-react, mas não funcionou
              "c-pointer":
                _vm.$hasPerm("lotesScLotesAuditarVarias") ||
                _vm.$hasPerm("lotesScLotesReabrirVarias")
            },
            attrs: {
              striped: "",
              hover: "",
              small: "",
              items: _vm.guias2show,
              fields: _vm.colunas,
              "tbody-tr-class": _vm.destacarLinha,
              selectable:
                _vm.$hasPerm("lotesScLotesAuditarVarias") ||
                _vm.$hasPerm("lotesScLotesReabrirVarias")
            },
            on: { "row-selected": _vm.onRowSelected },
            scopedSlots: _vm._u([
              {
                key: "cell(selected)",
                fn: function(ref) {
                  var rowSelected = ref.rowSelected
                  return [
                    rowSelected
                      ? [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("✓")
                          ]),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Selected")
                          ])
                        ]
                      : [
                          _c("span", { attrs: { "aria-hidden": "true" } }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Not selected")
                          ])
                        ]
                  ]
                }
              },
              {
                key: "cell(valorTotal)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toCurrency")(data.item.valorTotal)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(glosa)",
                fn: function(data) {
                  return [
                    data.item.glosa > 0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toCurrency")(data.item.glosa)) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "cell(valorFinal)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toCurrency")(data.item.valorFinal)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(status)",
                fn: function(data) {
                  return [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.drawStatusAuditoria(
                            data.item.status,
                            data.item.statusComAlteracao
                          )
                        )
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(action)",
                fn: function(data) {
                  return [
                    _c(
                      "b-button-group",
                      { staticClass: "w-100 ", attrs: { size: "sm" } },
                      [
                        data.item.ativo
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "authorize",
                                    rawName: "v-authorize",
                                    value: "lotesScLotes",
                                    expression: "'lotesScLotes'"
                                  }
                                ],
                                staticClass: "flex-grow-0",
                                attrs: {
                                  variant: "outline-primary",
                                  title: "ver"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name:
                                        "faturamento.lotes.detalheguia.visualizar",
                                      params: {
                                        arquivoId: _vm.model.id,
                                        guiaIndex: data.item.index,
                                        numeroLote: _vm.model.lote
                                      }
                                    })
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "uil uil-eye icon-size"
                                })
                              ]
                            )
                          : _vm._e(),
                        data.item.ativo &&
                        _vm.editavel &&
                        data.item.status != "A"
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "authorize",
                                    rawName: "v-authorize",
                                    value: "lotesScLotesAuditar",
                                    expression: "'lotesScLotesAuditar'"
                                  }
                                ],
                                staticClass: "flex-grow-0",
                                attrs: {
                                  variant: "outline-primary",
                                  title: "Auditar",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name:
                                        "faturamento.lotes.detalheguia.auditar",
                                      params: {
                                        arquivoId: _vm.model.id,
                                        guiaIndex: data.item.index,
                                        numeroLote: _vm.model.lote
                                      }
                                    })
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "uil uil-pen icon-size"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.editavel &&
                        data.item.status != "A" &&
                        data.item.ativo
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "authorize",
                                    rawName: "v-authorize",
                                    value: "lotesScLotesRemoverGuia",
                                    expression: "'lotesScLotesRemoverGuia'"
                                  }
                                ],
                                staticClass: "flex-grow-0 ms-auto",
                                attrs: {
                                  variant: "outline-primary",
                                  title: "Remover guias",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRemoverGuia(data.item)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-eraser icon-size"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.editavel &&
                        data.item.status != "A" &&
                        !data.item.ativo
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "authorize",
                                    rawName: "v-authorize",
                                    value: "lotesScLotesReativarGuia",
                                    expression: "'lotesScLotesReativarGuia'"
                                  }
                                ],
                                staticClass: "flex-grow-0 ms-auto",
                                attrs: {
                                  variant: "outline-primary",
                                  title: "Reativar guias",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleReativarGuia(data.item)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-undo icon-size"
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "p",
            [
              !_vm.selecionando
                ? _c(
                    "small",
                    {
                      directives: [
                        {
                          name: "authorize",
                          rawName: "v-authorize",
                          value: [
                            "lotesScLotesAuditarVarias",
                            "lotesScLotesReabrirVarias"
                          ],
                          expression:
                            "['lotesScLotesAuditarVarias', 'lotesScLotesReabrirVarias']"
                        }
                      ],
                      staticClass: "text-muted"
                    },
                    [_vm._v(" * Clique na linha para selecionar várias guias ")]
                  )
                : _vm._e(),
              _c(
                "b-button-group",
                { attrs: { size: "sm" } },
                [
                  _vm.selecionando
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "authorize",
                              rawName: "v-authorize",
                              value: "lotesScLotesAuditarVarias",
                              expression: "'lotesScLotesAuditarVarias'"
                            }
                          ],
                          attrs: {
                            variant: "outline-primary",
                            title: "Encerrar auditoria das selecionadas",
                            small: ""
                          },
                          on: { click: _vm.handleEncerrarAuditoriaSelecionadas }
                        },
                        [
                          _c("i", { staticClass: "uil uil-save" }),
                          _vm._v(" Encerrar auditoria das selecionadas ")
                        ]
                      )
                    : _vm._e(),
                  _vm.selecionando
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "authorize",
                              rawName: "v-authorize",
                              value: "lotesScLotesReabrirVarias",
                              expression: "'lotesScLotesReabrirVarias'"
                            }
                          ],
                          attrs: {
                            variant: "outline-primary",
                            title: "Reabrir auditorias selecionadas",
                            small: ""
                          },
                          on: { click: _vm.handleReabrirAuditoriaSelecionadas }
                        },
                        [
                          _c("i", { staticClass: "uil uil-sync-exclamation" }),
                          _vm._v(" Reabrir auditorias selecionadas ")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-representacao",
          attrs: {
            title: "Representação na assinatura digital",
            hideHeaderClose: true,
            centered: true,
            "ok-only": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "me-2",
                          attrs: { variant: "info", size: "sm" },
                          on: {
                            click: function() {
                              _vm.isModalRepresentacaoOpen = false
                            }
                          }
                        },
                        [_vm._v(" Cancelar ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "me-2",
                          attrs: { variant: "success", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.utilizarRepresentante()
                            }
                          }
                        },
                        [_vm._v(" Baixar ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isModalRepresentacaoOpen,
            callback: function($$v) {
              _vm.isModalRepresentacaoOpen = $$v
            },
            expression: "isModalRepresentacaoOpen"
          }
        },
        [
          _c(
            "div",
            { staticClass: "filtro-inline" },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-radio-group", {
                    attrs: { options: _vm.optionsRepresentacao },
                    model: {
                      value: _vm.modalRepresentacao.usarAssinatura,
                      callback: function($$v) {
                        _vm.$set(_vm.modalRepresentacao, "usarAssinatura", $$v)
                      },
                      expression: "modalRepresentacao.usarAssinatura"
                    }
                  })
                ],
                1
              ),
              _vm.modalRepresentacao.usarAssinatura == "S"
                ? _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.modalRepresentacao.usarAssinaturaMedico,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.modalRepresentacao,
                                "usarAssinaturaMedico",
                                $$v
                              )
                            },
                            expression:
                              "modalRepresentacao.usarAssinaturaMedico"
                          }
                        },
                        [_vm._v("Usar assinatura do médico")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.modalRepresentacao.usarAssinatura == "S" &&
              _vm.modalRepresentacao.usarAssinaturaMedico
                ? _c("f-selectsearch", {
                    ref: "modalUsuarioId",
                    attrs: {
                      label: "Selecione o usuário:",
                      name: "modalUsuarioId",
                      cols: 12,
                      searchFunction: _vm.pesquisarRepresentanteMedico,
                      labelField: "nome"
                    },
                    on: { select: _vm.addRepresentanteMedico },
                    model: {
                      value: _vm.modalRepresentacao.representanteMedicoId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.modalRepresentacao,
                          "representanteMedicoId",
                          $$v
                        )
                      },
                      expression: "modalRepresentacao.representanteMedicoId"
                    }
                  })
                : _vm._e(),
              _vm.modalRepresentacao.usarAssinatura == "S"
                ? _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.modalRepresentacao.usarAssinaturaEnf,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.modalRepresentacao,
                                "usarAssinaturaEnf",
                                $$v
                              )
                            },
                            expression: "modalRepresentacao.usarAssinaturaEnf"
                          }
                        },
                        [_vm._v("Usar assinatura do enfermeiro")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.modalRepresentacao.usarAssinatura == "S" &&
              _vm.modalRepresentacao.usarAssinaturaEnf
                ? _c("f-selectsearch", {
                    ref: "modalUsuarioId",
                    attrs: {
                      label: "Selecione o usuário:",
                      name: "modalUsuarioId",
                      cols: 12,
                      searchFunction: _vm.pesquisarRepresentanteEnf,
                      labelField: "nome"
                    },
                    on: { select: _vm.addRepresentanteEnf },
                    model: {
                      value: _vm.modalRepresentacao.representanteEnfId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.modalRepresentacao,
                          "representanteEnfId",
                          $$v
                        )
                      },
                      expression: "modalRepresentacao.representanteEnfId"
                    }
                  })
                : _vm._e(),
              _c("f-selectsearch", {
                attrs: {
                  label: "Selecione guias específicas:",
                  name: "modalGuias",
                  cols: 12,
                  multiple: true,
                  searchFunction: _vm.pesquisarRepresentanteGuia,
                  searchAllOptions: true,
                  labelField: "guia",
                  valueField: "guia"
                },
                on: { select: _vm.addRepresentanteGuia },
                model: {
                  value: _vm.modalRepresentacao.guias,
                  callback: function($$v) {
                    _vm.$set(_vm.modalRepresentacao, "guias", $$v)
                  },
                  expression: "modalRepresentacao.guias"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }